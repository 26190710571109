* {
  /* border: 1px dashed red; */
}

body {
  background-color: #101010;
  border: none;
}

h1 {
  font-size: 3.25rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size: 1rem;
}

.text-shadow-100 {
  text-shadow:
    0px 0px 40px rgba(0, 0, 0, 1),
    0px 0px 40px rgba(0, 0, 0, 1),
    0px 0px 40px rgba(0, 0, 0, 1),
    2px 2px 4px rgba(0, 0, 0, 1);
}

.text-shadow-50 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.drop-shadow-100 {
  filter: drop-shadow(0px 0px 7px black) drop-shadow(0px 0px 7px black);
}

.app_button {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.app_button:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-app_button 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;

  filter: blur(4px);
  -webkit-filter: blur(4px);

  @media (min-width: 768px) {
    filter: blur(10px);
    -webkit-filter: blur(10px);
  }
}

@keyframes glowing-app_button {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.app_button:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

/* Used for the blinking cursor in the TagLine */
@keyframes flash {
  0%,
  49.9% {
    opacity: 1;
  }

  50%,
  100% {
    opacity: 0;
  }
}

.flash {
  animation: flash 1s infinite;
}