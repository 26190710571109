@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Manrope";
  font-weight: 500;
  src: url("assets/font/Manrope-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Manrope";
  font-weight: 700;
  src: url("assets/font/Manrope-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Manrope";
  font-weight: 900;
  src: url("assets/font/Manrope-ExtraBold.ttf") format("truetype");
}

html {
  font-size: clamp(6pt, 2vw, 12pt);
}

body {
  font-family: "manrope";
  background-color: #101010;
  color: white;
}

h1 {
  @apply font-bold;
  font-size: 4rem;
  line-height: 5.6rem;
}

h2 {
  @apply font-bold;
  font-size: 3rem;
  line-height: 4rem;
}

h4 {
  font-size: max(14pt, 1.8rem);
}

h5 {
  font-size: max(12pt, 1.4rem);
}

h6 {
  font-size: max(12pt, 0.5rem);
}

/* When doing gradient text, use this to have it work on Chromium and Firefox */
.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}
